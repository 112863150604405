import get from 'lodash/get';

import {
  checkDangerKahlMessages,
  checkSuccessKahlMessages,
} from '../../api/requestUtils';
import TtsApi from '../../api/ttsApi';
import { codeListPagesMap } from '../../components/constants/codeListToRouteMap';
import { getState } from '../store/store';
import { setWaitingHolders } from './paramsHolderActions';
import { redirectNotSupportedModem } from './pageActions';

export type Action = {
  type:
  | 'PROCESS'
  | 'TTS_PROCESS'
  | 'TTS_PROCESS_ANALYTICS'
  | 'TTS_PROCESS_WAITING_SUCCESS'
  | 'SAVE_SELECTED_VALUE'
  | 'START_TTS_PROCESS'
  | 'LAZYLOAD_BATCH'
  | 'FINISH_TTS_PROCESS'
  | 'FILTERING_SERVICES_SUCCESS'
  | 'CLEAR_TTS';
  payload: any;
};

const startTTSCall = () => {
  return {
    type: 'START_TTS_PROCESS',
    payload: {},
  };
};

const finishTTSCall = () => {
  return {
    type: 'FINISH_TTS_PROCESS',
    payload: {},
  };
};

export const clearTTSParams = () => {
  return {
    type: 'CLEAR_TTS',
    payload: {},
  };
};

const filteringServices = (services: any) => {
  return {
    type: 'FILTERING_SERVICES_SUCCESS',
    payload: services,
  };
};

export const saveTtsParams = (
  isTtsProcess: boolean,
  ttsParams: any
): Action => {
  return {
    type: 'TTS_PROCESS',
    payload: {
      isTtsProcess,
      ttsParams,
    },
  };
};

export const saveTssParamsAnalytics = (ttsParams: any, params: any) => {
  return {
    type: 'TTS_PROCESS_ANALYTICS',
    payload: {
      ttId: ttsParams?.ttId,
      serviceCpAssetRefNo: params.serviceCpAssetRefNo,
      address: params.address,
    },
  };
};

const updatePageReducer = (pageName: string, data: any) => {
  return {
    type: 'PROCESS',
    payload: {
      pageName,
      data,
    },
  };
};

const lazyLoadBatch = (batch: number) => {
  return {
    type: 'LAZYLOAD_BATCH',
    payload: batch,
  };
};

const ttsProcessWaitingSuccess = (isSuccess: boolean, nextPage: string) => {
  return {
    type: 'TTS_PROCESS_WAITING_SUCCESS',
    payload: {
      isSuccess,
      nextPage,
    },
  };
};

const saveValueID = (valueId: string) => {
  return {
    type: 'SAVE_SELECTED_VALUE',
    payload: valueId,
  };
};

export const ttsProcess = (
  body: any,
  props: any = undefined,
  noTTSParamsForce: boolean = false,
  analyticsUrl?: any
) => {
  const { user, customerList, sharedObjects } = getState();
  if (!user.isLoggedIn) return;

  const inProgress = getState().pages.ttsInProgress;
  const isTtsProcess = getState().pages.isTtsProcess;
  if (isTtsProcess && !body.ttsParams && !noTTSParamsForce) {
    body.ttsParams = getState().pages.ttsParams;
  }

  if (customerList.customerListResponse !== null) {
    body.params.cuRefNo = customerList.customerListResponse.customers[0].cuRefNo;
  } else {
    body.params.cuRefNo = sharedObjects?.sharedObjectSession?.customer?.cuRefNo
  }

  return (dispatch: any) => {
    if (!inProgress) {
      const serviceId = get(body, 'params.serviceId', null);
      const lastPage = get(body, 'page', null);
      dispatch(setWaitingHolders(serviceId, lastPage));

      if (body.params && body.params.serviceId) {
        dispatch(saveValueID(body.params.serviceId));
      } else if (body.params && body.params.troubleId) {
        dispatch(saveValueID(body.params.troubleId));
      }
      dispatch(startTTSCall());

      const processAPI = new TtsApi();
      processAPI
        .call(body)
        .then(res => {
          checkSuccessKahlMessages(res.data);
          dispatch(finishTTSCall());
          const nextPage = get(res, 'data.nextPage', null);
          if (
            nextPage === 'waiting' &&
            get(res, 'data.params.serviceId', null) !== null
          ) {
            dispatch(
              setWaitingHolders(
                get(res, 'data.params.serviceId', null),
                lastPage
              )
            );
          }
          console.log(nextPage);
          if (
            !(lastPage === 'waiting' && lastPage === nextPage) ||
            body.params.lazyloadBatch
          ) {
            if (body.params !== undefined && body.params.lazyloadBatch) {
              dispatch(lazyLoadBatch(body.params.lazyloadBatch));
            } else {
              dispatch(lazyLoadBatch(0));
            }
            const ttsParams = get(res, 'data.ttsParams', null);
            const params = get(res, 'data.params', null);
            if (ttsParams && ttsParams !== null) {
              if (ttsParams.lastStep) {
                dispatch(saveTtsParams(false, {}));
              } else {
                dispatch(saveTtsParams(true, ttsParams));
              }
              if (ttsParams && params) {
                dispatch(saveTssParamsAnalytics(ttsParams, params));
              }
            } else {
              dispatch(saveTtsParams(false, {}));
            }

            if (ttsParams && ttsParams.destinationId) {
              const destinationToPage = getState().appCache.codeLists
                .destinationToPage;
              const resolvedUrl =
                codeListPagesMap[destinationToPage[ttsParams.destinationId]];
              dispatch(
                updatePageReducer(
                  destinationToPage[ttsParams.destinationId],
                  res.data
                )
              );
              if (resolvedUrl && props) {
                props.history.push(resolvedUrl);
              }
            }
            if (nextPage) {
              let resolvedUrl = codeListPagesMap[nextPage];
              if (nextPage === 'troubleDetail') {
                const ttId = get(res, 'data.troubles.0.id', null);
                if (ttId === null) {
                  resolvedUrl = '/funnel?jwtChecked=true';
                }
                resolvedUrl = resolvedUrl + ttId;
              }
              dispatch(updatePageReducer(nextPage, res.data));
              if (resolvedUrl && props) {
                let resultUrl =
                  analyticsUrl && analyticsUrl !== '&'
                    ? `${resolvedUrl}${analyticsUrl}`
                    : resolvedUrl;
                props.history.push(resultUrl);
              }
            }
          }
        })
        .catch((err: any) => {
          dispatch(finishTTSCall());
          if (err.response && err.response.status === 403) {
            props.history.push('/funnel');
          }
          if (err.response) {
            checkDangerKahlMessages(err.response.data);
          }
          if (err.response && err.response.data && err.response.data.nextPage) {
            const nextPage = get(err.response, 'data.nextPage', null);
            if (nextPage) {
              let resolvedUrl = codeListPagesMap[nextPage];
              if (nextPage === 'troubleDetail') {
                const ttId = get(err.response, 'data.troubles.0.id', null);
                if (ttId === null) {
                  resolvedUrl = '/funnel?jwtChecked=true';
                }
                resolvedUrl = resolvedUrl + ttId;
              }
              dispatch(updatePageReducer(nextPage, err.response.data));
              if (resolvedUrl && props) {
                let resultUrl =
                  analyticsUrl && analyticsUrl !== '&'
                    ? `${resolvedUrl}${analyticsUrl}`
                    : resolvedUrl;
                props.history.push(resultUrl);
              }
            }
          }
        });
    }
  };
};

export const ttsProcessWaiting = (body: any) => {
  const inProgress = getState().pages.ttsInProgress;
  const isTtsProcess = getState().pages.isTtsProcess;
  if (isTtsProcess && !body.ttsParams) {
    body.ttsParams = getState().pages.ttsParams;
  }
  return (dispatch: any) => {
    if (!inProgress) {
      const lastPage = get(body, 'page', null);
      const serviceId = get(body, 'params.serviceId', null);

      if (serviceId !== null) {
        dispatch(setWaitingHolders(serviceId, lastPage));
      }

      dispatch(startTTSCall());

      const processAPI = new TtsApi();
      processAPI
        .call(body)
        .then(res => {
          // returns boolean and calls dispatch to addSuccessKahlMessage if true
          // const isKahl = true;
          const isKahl = checkSuccessKahlMessages(res.data);
          dispatch(finishTTSCall());
          const ttsParams = get(res, 'data.ttsParams', null);
          if (ttsParams && ttsParams !== null) {
            if (ttsParams.lastStep) {
              dispatch(saveTtsParams(false, {}));
            } else {
              dispatch(saveTtsParams(true, ttsParams));
            }
          } else {
            dispatch(saveTtsParams(false, {}));
          }
          const nextPage = get(res, 'data.nextPage', null);

          // not supported modem edge case
          if (nextPage === 'notSupportedModem') {
            if (isKahl) {
              // stay here and display kahl messages
              dispatch(redirectNotSupportedModem(false));
            } else {
              // go away
              dispatch(redirectNotSupportedModem(true));
            }
          }

          const resolvedUrl = codeListPagesMap[nextPage];

          if (nextPage !== 'waiting') {
            dispatch(ttsProcessWaitingSuccess(true, resolvedUrl));
            dispatch(updatePageReducer(nextPage, res.data));
          }
        })
        .catch(err => {
          dispatch(finishTTSCall());
          checkDangerKahlMessages(err.response?.data);
          console.log(err);
        });
    }
  };
};

export const ttsProcessFiltering = (body: any) => {
  return (dispatch: any) => {
    const processAPI = new TtsApi();
    processAPI
      .call(body)
      .then(res => {
        checkSuccessKahlMessages(res.data);
        dispatch(filteringServices(res.data.services));
      })
      .catch(err => {
        checkDangerKahlMessages(err.response.data);
        console.log(err);
      });
  };
};
